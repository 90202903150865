import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Loader from '../App/components/Loader';
import AlertModalDialog from '../App/components/AlertModalDialog';
import InputKeyboardDatePicker from '../App/components/InputKeyboardDatePicker';
import HistoryTable from './components/HistoryTable';
import { filterHistory, setHistory } from './historyActions';
import { RESET_HISTORY } from "./historyReducers";
import './styles.css';
import { ToastContainer } from 'react-toastify';
import Toast from '../App/utils/Toast';

export default function History() {

    const { history } = useSelector((state) => state.history);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [initialDate, setInitialDate] = useState(() => {
        const today = new Date();
        return new Date(today.setMonth(today.getMonth()));
    });

    const [finalDate, setFinalDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [constructor, setConstructor] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {

        if (constructor) {

            dispatch({ type: RESET_HISTORY });

            dispatch(setHistory({ offset: 0, limit: 10 }, () => { }));
        }

        setConstructor(false)

        setLoading(false)

    }, [constructor, dispatch])

    function handleFilterHistory() {

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(filterHistory({ initialDate, finalDate }, user.establishmentUser[0].id_establishment, (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {
                return openModal('Aviso', resp);
            }

            if (typeof resp === 'boolean' && !resp) {
                return openModal('ERRO', 'Erro no envio das datas para filtrar');
            }

            Toast.dismiss();
        }));
    }

    function openModal(title, text) {

        setModal({
            open: true,
            title,
            text
        });
    }

    function getTableData() {

        if (history.length === 0)

            return (<Card id="history-content-without-data">

                <Typography variant="h6">Lista dos históricos</Typography>

                <div className="no-result-content">
                    <Typography variant="subtitle1">Que pena... Você não utilizou o estacione fácil até agora =[</Typography>
                </div>

            </Card>)

        return (<Card id="history-content-with-data">

            <Typography variant="h6" align="left">Lista dos históricos</Typography>
            <Typography variant="h6" align="right">Qtde: {history.length}</Typography>

            <div className="result-content">
                <HistoryTable resultSet={history} />
            </div>
        </Card>)
    }

    return (
        <Grid container>

            <ToastContainer/>

            <Grid item xs={12}>
                <Card id="header-container">
                    <div className="title-content">
                        <Typography variant="h4">Históricos</Typography>
                        <Typography variant="h6">Busca dos estacionamentos utilizados</Typography>
                    </div>
                    <div className="history-filter-content">
                        <InputKeyboardDatePicker
                            label="Data inicial"
                            value={initialDate}
                            onChange={(date) => setInitialDate(date)}
                            className="filter-itens"
                        />
                        <InputKeyboardDatePicker
                            label="Data final"
                            value={finalDate}
                            onChange={(date) => setFinalDate(date)}
                            className="filter-itens"
                        />
                        <Button
                            color="primary"
                            variant="outlined"
                            className="filter-itens"
                            onClick={handleFilterHistory}
                        >
                            Filtrar
                        </Button>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {!loading ? getTableData() : <Loader loading={loading} />}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}
