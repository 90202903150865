export const SET_USER = 'SET_USER';
export const SET_USER_CELLPHONE_NUMBER = 'SET_USER_CELLPHONE_NUMBER';
export const RESET_USER = 'RESET_USER';

const INITIAL_STATE = {
    user: {
        id: 0
    },
    cellphoneNumber: ''
}

export function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case SET_USER_CELLPHONE_NUMBER:
            return {
                ...state,
                cellphoneNumber: action.payload
            }
        case RESET_USER:
            return INITIAL_STATE
        default:
            return state
    }
}