const StorageService = {
    add: (name, data) => {
        if (typeof name !== 'string') {
            console.error('Nome do objeto é invalido');
            return false;
        }

        switch (typeof data) {
            case 'object':
                return localStorage.setItem(name, JSON.stringify(data));
            case 'function':
                console.error('Você não pode armazernar uma function na sessao!');
                return false;
            case 'string':
                return localStorage.setItem(name, data);
            default:
                console.error('Dados com formato invalido!');
                return false;
        }
    },
    get: (name, object) => {
        if (object === void 0) { object = false; }

        if (!name) {
            alert('Nome do objeto é invalido');
            return false;
        }
        
        const key = String(name);
        if (object)
            return JSON.parse(localStorage.getItem(key));
        else
            return localStorage.getItem(key);
    }
}

export default StorageService;