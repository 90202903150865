import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useSelector} from "react-redux";

const classesA = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}))

export default function AppBackdrop(){
    const styles = classesA()
    const backdropIsOpen = useSelector(state => state.app.backdropIsOpen)

    return (<Backdrop  className={styles.backdrop} open={backdropIsOpen} >
        <CircularProgress color="inherit" />
    </Backdrop>)
}