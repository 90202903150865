import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useSelector } from 'react-redux';
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Home from "@material-ui/icons/Home";
import AccessTime from "@material-ui/icons/AccessTime";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import useStyles from "../hooks/useStyles";
import {useColors} from "../hooks/useColors";
import ListItemLink from "./ListItemLink";
import AlertModalDialog from './AlertModalDialog';
import './styles.css';

const MozartDrawer = (props) => {
    const { user } = useSelector((state) => state.user);
    const classes = useStyles();
    const colors = useColors();
    const history = useHistory();
    const [modal, setModal] = useState({
        open: false,
        title: 'Confirmar',
        text: 'Deseja encerrar sessão?',
    });

    useEffect(() => {
        if (!user.hasOwnProperty('id')) {
            window.localStorage.clear();
            history.push('/');
        }
    }, [user, history])

    function handleSignout() {
        window.localStorage.clear();
        history.push('/');
        setModal({ ...modal, open: false });
    }

    return (
        <Drawer
            id="drawer-menu"
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.open,
                [classes.drawerClose]: !props.open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                }),
            }}
        >
            <div className={clsx({
                [classes.toolbarOpenDrawer]: props.open,
                [classes.toolbar]: !props.open,
            })}>
                <IconButton onClick={() => props.setOpen(false)}>
                    {props.theme.direction === 'ltr' && <ChevronLeft style={{ color: colors.orange }} />}
                </IconButton>
            </div>
            {props.open ? (
                <div className={classes.openMyData} id="drawer-my-data">
                    <Avatar className="avatar">{user.hasOwnProperty('id') && user?.name.charAt(0)}</Avatar>
                    <Typography variant="h6" noWrap className={classes.userText}>{user.hasOwnProperty('id') && user.name}</Typography>
                    <Typography variant="subtitle2" noWrap className={classes.userText}>{user.hasOwnProperty('id') && user.email}</Typography>
                </div>
            ) : (
                <div className={classes.closeMyData} id="drawer-my-data">
                    <Avatar className="avatar">{user.hasOwnProperty('id') && user?.name.charAt(0)}</Avatar>
                </div>
            )}
            <Divider />
            <List onClick={() => props.setOpen(false)} >
                <ListItemLink button icon={<Home style={{ color: colors.orange, marginLeft: 7 }} />} primary='Dashboard' to='/dashboard' />
                <ListItemLink button icon={<AccessTime style={{ color: colors.orange, marginLeft: 7 }} />} primary='Histórico' to='/history' />
                <ListItemLink button icon={<AccountCircle style={{ color: colors.orange, marginLeft: 7 }} />} primary='Minha conta' to='/myaccount' />
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => { setModal({ ...modal, open: true }) }}>
                    <ListItemIcon><ExitToApp style={{ color: colors.orange, marginLeft: 7 }} /></ListItemIcon>
                    <ListItemText primary='Sair' />
                </ListItem>
            </List>

            <AlertModalDialog
                open={modal}
                setOpen={handleSignout}
                cancel={() => { setModal({ ...modal, open: false }) }}
            />
        </Drawer>
    );
}

export default MozartDrawer;