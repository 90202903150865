import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import RegisterWidget from './components/RegisterWidget';
import SummaryHistoryTable from './components/SummaryHistoryTable';
import ConfirmBarcodeDialog from './components/ConfirmBarcodeDialog';
import {setHistory} from '../History/historyActions';
import {sendBarcodeRegister, sendConfirmRegister, sendConfirmRegister2} from './registerActions';
import Loader from '../App/components/Loader';
import AlertModalDialog from '../App/components/AlertModalDialog';
import './styles.css';
import {SauronApi} from "../App/services/SauronApi";
import { RESET_HISTORY } from "../History/historyReducers";
// import { toast } from 'react-toastify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../App/utils/Toast';


export default function Dashboard() {

    const {history} = useSelector((state) => state.history);
    const {parking} = useSelector((state) => state.register);
    const {user} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [isFirstContruct, setIsFirstContruct] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [barcode, setBarcode] = useState('');
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    // toast.configure()

    // console.log('lerCodigo')

    useEffect(() => {

        if (isFirstContruct) {

            setIsFirstContruct(false)

            dispatch({ type: RESET_HISTORY });

            dispatch(setHistory({offset: 0, limit: 10}, () => {}));
        }

        setLoading(false);

    }, [dispatch, history, isFirstContruct]);


    function handleRegisterBarcode(searchType, value) {

        // value = '1-4893'

        // setLoading(true);

        setBarcode(value);

        /*id = toast.loading('Aguarde... Ticket: '+value, {
                            position: "top-right",
                            pauseOnHover: true,
                            closeOnClick: true,
                            draggable: true,
                            progress: undefined,
                            hideProgressBar: true,
                        });*/

        Toast.loading('Aguarde... Validando Ticket: '+value);

        if (user.licensing[0].confirmar_veiculo_convenio) {

            dispatch(sendBarcodeRegister(searchType, value, false, user.establishmentUser[0].id_establishment,(resp) => {

                setLoading(false);

                if (typeof resp === 'object') {

                    Toast.dismiss();

                    return setOpenConfirmDialog(true);
                }

                Toast.showToast(resp +" - Ticket: "+value, '');

                // toast.update(id, {render: resp +" - Ticket: "+value, theme: "colored", type: "error", isLoading: false, autoClose: 3000, })

                /*setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })*/
            }))
        }
        else {

            dispatch(sendBarcodeRegister(searchType, value, user.licensing[0].insert_licensing_list, user.establishmentUser[0].id_establishment,(resp) => {

                setLoading(false);

                if (typeof resp === 'object') {

                    Toast.showToast(true, "Vinculado com sucesso!!! Ticket: "+value);

                    return
                }

                Toast.showToast(resp +" - Ticket: "+value, '');

                // toast.update(id, {render: resp +" - Ticket: "+value, theme: "colored", type: "error", isLoading: false, autoClose: 3000, })

                /*setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })*/
            }))
        }
    }

    async function handleConfirmDialog(free, data) {

        setOpenConfirmDialog(false);

        Toast.loading('Aguarde... Validando Ticket: '+data.external_id);

        dispatch(sendBarcodeRegister('ticket', data.external_id, true, user.establishmentUser[0].id_establishment,(resp) => {

            setLoading(false);


            if (typeof resp === 'object') {


                Toast.showToast(true, "Vinculado com sucesso!!! Ticket: "+data.external_id);

                return
            }

            Toast.showToast(resp +" - Ticket: "+data.external_id, '');
        }))

    }

    async function handleConfirmDialog1(free, filteredParking) {

        setOpenConfirmDialog(false);

        // se for gravado na lista de sincronismo do servidor, não envia para o server local do estacionamento
        // o servidor que irá sincronizar
        if (user.licensing[0].insert_licensing_list) {

            // toast.update(id, {render: "Vinculado com sucesso!!! Ticket: "+filteredParking.external_id, theme: "colored", type: "success", isLoading: false, autoClose: 2000, })

            Toast.showToast(true, "Vinculado com sucesso!!! Ticket: "+filteredParking.external_id);

            return
        }

        // setLoading(true);

        /*const response = await SauronApi.post('/ping', {
            client_id: parking.id_establishment
        })

        console.log('pong')

        if (response.action !== 'success' || response.connected !== 'true') {

            alert('Sistema indisponível ou Sem Conexão.');

            setLoading(false);

            return;
        }*/

        const params = {

            client_id:      filteredParking.id_establishment,
            free,
            ticket_id:      filteredParking.external_id,
            licensing:      user.licensing[0].id,
            document:       user.licensing[0].document,
            phone_number:   '14991527385',
        }

        await dispatch(sendConfirmRegister({params, filteredParking}, (resp) => {

            setLoading(false);

            if (resp === true) {

                Toast.showToast(true, "Vinculado com sucesso!!! Ticket: "+filteredParking.external_id);

                return
            }
            else {

                Toast.showToast(resp +" - Ticket: "+filteredParking.external_id, '');

                return
            }
        }))
    }

    async function handleConfirmDialog2(free) {

        setOpenConfirmDialog(false);

        setLoading(true);

        const params = {

            id_parking: parking.id,
            phone_number: '14991527385',
        }

        await dispatch(sendConfirmRegister2(params, (resp) => {

            return setModal({
                open: true,
                title: typeof resp === 'boolean' ? 'Pronto!' : 'Ooops!',
                text: typeof resp === 'boolean' ? 'Estacionamento vinculado com sucesso!' : resp
            });
        }))

        setLoading(false);
    }

    function getTableData() {

        if (history.length === 0) {
            return (<Card id="dashboard-history-content-without-data">
                <Typography variant="h6">Últimos estacionamentos vinculados</Typography>
                <div className="no-result-content">
                    <Typography variant="subtitle1">Que pena... Você não utilizou o estacione fácil até agora
                        =[
                    </Typography>
                </div>
            </Card>)
        }

        return (<Card id="dashboard-history-content-with-data">

            <Typography variant="h6">Últimos estacionamentos vinculados</Typography>

                <SummaryHistoryTable resultSet={history}/>
        </Card>)
    }

    return (
        <Grid container>

            <ToastContainer />

            <Grid item xs={12}>
                <RegisterWidget
                    onRegister={handleRegisterBarcode}
                />
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : <Loader loading={loading}/>}
            </Grid>

            {openConfirmDialog && (
                <ConfirmBarcodeDialog
                    open={openConfirmDialog}
                    data={parking}
                    barcode={barcode}
                    onConfirm={handleConfirmDialog}
                    cancel={() => setOpenConfirmDialog(false)}
                />
            )}
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({...modal, open: false})}
            />
        </Grid>
    );
}
