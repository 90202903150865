export const SET_HISTORY = 'SET_HISTORY';
export const FILTER_HISTORY = 'FILTER_HISTORY';
export const RESET_HISTORY = 'RESET_HISTORY';

const INITIAL_STATE = {

    history: [],
    affectedRows: 1,
    where: {
        offset: 0,
        limit: 10,
    }
}

export function historyReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_HISTORY:

            const newHistory    = [...state.history, ...action.payload];
            const newOffset     = action.payload.length;

            return {
                ...state,
                history: newHistory,
                affectedRows: newOffset < state.where.limit ? 0 : 1,
                where: {
                    ...state.where,
                    offset: state.where.offset + newOffset
                }
            }

        case FILTER_HISTORY:

            const filterHistory = [...state.history, ...action.payload];

            return {
                ...state,
                history: filterHistory,
            }

        case RESET_HISTORY:
            return {
                ...INITIAL_STATE,
            }
        default:
            return state
    }
}