import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from './App/services/HistoryService';
import AuthenticatedRouter from "./App/AuthenticatedRouter";
import { store, persistor } from './App/store';
import Loader from './App/components/Loader';
import Dashboard from "./Dashboard";
import History from "./History";
import MyAccount from "./MyAccount";
import './styles.css';
// import Home from "./Home";
import Login from "./Login";
import AppBackdrop from "./App/components/AppBackdrop";

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Loader loading={true} />} persistor={persistor}>
            <AppBackdrop />
            <Router history={history}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/login" exact component={Login} />
                        <AuthenticatedRouter path="/dashboard" component={Dashboard} />
                        <AuthenticatedRouter path="/history" component={History} />
                        <AuthenticatedRouter path="/myaccount" component={MyAccount} />
                        <Route path="*" component={() => <h1>Page not found</h1>} />
                    </Switch>
                </BrowserRouter>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);