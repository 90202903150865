import { format, compareDesc } from 'date-fns'
import { ApiService } from '../App/services/ApiService';
import { SET_HISTORY, FILTER_HISTORY, RESET_HISTORY } from './historyReducers';

export function setHistory(where, callback) {

    const currentDate = format(new Date(), 'yyyy-MM-dd');

    return dispatch => {

        ApiService.get(`/reports/licensing/parking-list?offset=${where.offset}&limit=${where.limit}&initDate=${currentDate}&finalDate=${currentDate}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {

                dispatch({
                    type: RESET_HISTORY
                });

                return callback(false);
            }

            if (response.success) {

                const history = response.resultSet.sort(compareDesc);

                dispatch({
                    type: SET_HISTORY,
                    payload: history
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}
export function filterHistory(date, id_establishment, callback) {

    const initialDate = format(date.initialDate, 'yyyy-MM-dd');
    const finalDate = format(date.finalDate, 'yyyy-MM-dd');

    return dispatch => {

        ApiService.get(`/reports/licensing/parking-list?initDate=${initialDate}&finalDate=${finalDate}&id_establishment=${id_establishment}`).then((response) => {

            dispatch({
                type: RESET_HISTORY
            });

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }

            if (response.success) {
                const history = response.resultSet.sort(compareDesc);

                dispatch({
                    type: FILTER_HISTORY,
                    payload: history
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}

export function resetHistory() {

    return {
        type: RESET_HISTORY
    }
}
