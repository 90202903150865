import GreatMask from './GreatMask';

const Validation = {
    onlyText: (value) => {
        const text = value.replace(/[^a-zA-Z-' ']/g, '');

        return value.length === text.length
    },
    onlyNumber: (value) => {
        const number = value.replace(/[^0-9]/g, '');

        return value.length === number.length
    },
    cellphone: (value) => {
        const number = value.replace(/^\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/, '');

        return value.length === number.length
    },
    cpf(strCPF) {
        let Soma;
        let Resto;
        let expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
        Soma = 0;

        if (strCPF.match(expReg))
            return false;
        for (let i = 1; i <= 9; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11))
            Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10)))
            return false;
        Soma = 0;
        for (let i = 1; i <= 10; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11))
            Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11)))
            return false;
        return true;
    },
    plaque(placa) {
        const regexPlaca = /^[A-Za-z]{3}[0-9]{4}$/;
        const regexPlacaMercosulCarro = /^[A-Za-z]{3}[0-9]{1}[A-Za-z]{1}[0-9]{2}$/;
        const regexPlacaMercosulMoto = /^[A-Za-z]{3}[0-9]{2}[A-Za-z]{1}[0-9]{1}$/;
        if (regexPlaca.test(placa) || regexPlacaMercosulCarro.test(placa) || regexPlacaMercosulMoto.test(placa)) {
            return true
        } else {
            return false;
        }
    },
    isCharacter(char) {
        return char.toLowerCase() !== char.toUpperCase();
    },
    birthdayAdult: (birthday) => {
        if (GreatMask.onlyNumber(birthday).length < 8) return false

        const dateSelected = GreatMask.onlyNumber(birthday).substring(0, 2)
        const monthSelected = GreatMask.onlyNumber(birthday).substring(2, 4);
        const yearSelected = GreatMask.onlyNumber(birthday).substring(4);

        let today = new Date();
        const dateToday = today.getDate();
        const monthToday = today.getMonth() + 1;
        const yearToday = today.getFullYear();

        let young = yearToday - yearSelected;
        if ((monthToday < monthSelected || monthSelected === monthToday) && dateToday < dateSelected) {
            young--;
        }

        if (young < 18 || yearSelected < (yearToday - 100)) return false;

        return true;
    }
}

export default Validation;