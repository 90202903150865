import React from 'react'
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";
import App from "./App";

const isAuthenticated = (user) => {

    const token = localStorage.getItem('token');

    try {

        return user.id > 0 && token;
    } 
    catch (error) {

        return false;
    }
}

function AuthenticatedRouter({ component: Component, ...rest }) {

    const { user } = useSelector((state) => state.user);
    
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated(user) ? (
                    <App><Component {...props} /></App>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                )
            }
        />
    );
}
export default AuthenticatedRouter;