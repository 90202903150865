import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function TextInputPassword(props) {
    const {
        label,
        value,
        onChange,
        error = false,
        validValue = undefined,
        minLength = 0,
        className
    } = props;
    const [textError, setTextError] = useState(false);
    const [textColor, setTextColor] = useState('primary');
    const [showPassword, setShowPassword] = useState(false);

    function onChangeBlur(value) {
        if (typeof validValue === 'string') {
            return setTextError(getValid(value.target.value, validValue) ? false : true)
        }

        if (error) {
            setTextError(value.target.value === '' ? true : false)
            setTextColor('secondary')
        } else {
            setTextError(false)
            setTextColor('primary')
        }
    }

    function getValid(value, type) {
        switch (type) {
            case 'minNumberText': return typeof minLength === 'number' ? value.length >= minLength : false;
            default: return false
        }
    }

    return (
        <FormControl className={className} variant="outlined">
            <InputLabel
                htmlFor="outlined-adornment-password"
                color={textColor}
            >
                {label}
            </InputLabel>
            
            <OutlinedInput
                id={`outlined-adornment-${label.trim()}`}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={(value) => { onChange(value) }}
                onBlur={(value) => { onChangeBlur(value) }}
                error={textError}
                required
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
                color={textColor}
            />
        </FormControl>
    )
}