import TextField from '@material-ui/core/TextField';

export default function FormikTextInput({ ...props }) {
    const {
        id,
        label,
        value,
        onChange,
        variant = "outlined",
        onKeyPress,
        InputLabelProps,
    } = props;

    return (
        <TextField
            id={id ? id : `standard-required-name-${label.trim()}`}
            value={value}
            onChange={(value) => { onChange(value) }}
            variant={variant}
            onKeyPress={(value) => { typeof onKeyPress === 'function' && onKeyPress(value) }}
            inputProps={InputLabelProps}
            {...props}
        />
    )
}