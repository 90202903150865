import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ptBR } from "date-fns/locale";

export default function InputKeyboardDatePicker(props) {
    const {
        id,
        label,
        value,
        onChange,
        inputVariant,
        className,
        format
    } = props;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant={inputVariant ? inputVariant : 'outlined'}
                autoOk
                id={id ? id : `date-picker-inline-${label.trim()}`}
                margin="normal"
                label={label}
                format={format ? format : 'dd/MM/yyyy'}
                value={value}
                disableFuture
                onChange={(date) => onChange(date)}
                className={className}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                helperText={''}
            />
        </MuiPickersUtilsProvider>
    )
}