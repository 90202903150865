import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useDispatch } from 'react-redux';
import TextInput from '../../App/components/TextInput';
import TextInputPassword from '../../App/components/TextInputPassword';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from "../../App/components/Loader";
import GreatMask from '../../App/utils/GreatMask';
import { changePassword, recoverPassword } from '../../MyAccount/userActions';
import './styles.css';

export default function ChangePassordTabPanel(props) {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        code: '',
        password: '',
        confirmPassword: ''
    });
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        if (props.username)
            setUsername(props.username)
        setStep('USERNAME')
        setLoading(false);
    }, [props]);

    useEffect(() => {
        if (username.trim() === '') {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [username])

    useEffect(() => {
        if (form.code.trim() === ''
            || form.password.length < 5
            || form.password !== form.confirmPassword) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [form])

    function handleChangePassword(e) {
        e.preventDefault();

        setLoading(true);
        dispatch(changePassword({
            password: form.password,
            code: form.code
        }, (resp) => {
            setLoading(false);
            if (typeof resp !== 'boolean' || !resp) {
                return setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })
            }

            setModal({
                open: true,
                title: 'Sucesso!',
                text: 'Senha alterado com sucesso!'
            })
            setStep('USERNAME');
        }));
    }

    function handleSubmitUsername(e) {
        e.preventDefault();

        setLoading(true);
        dispatch(recoverPassword(username, (resp) => {
            setLoading(false);
            if (typeof resp !== 'boolean' || !resp) {
                return setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })
            }

            setStep('PASSWORD');
        }));
    }

    function getComponent(ENUM) {
        switch (ENUM) {
            case 'USERNAME': return (
                <div className="change-password-username">
                    <p>Insira o número do celular ou email cadastrado para podermos enviar o código de segurança e recuperar a senha!</p>
                    <TextInput
                        label="Email / Celular"
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        className="change-password-field"
                        required
                        error
                    />
                    <button
                        className="button button-blue change-password-field"
                        onClick={handleSubmitUsername}
                        disabled={buttonDisabled}
                    >
                        Solicitar alteração de senha
                    </button>
                </div>
            );
            case 'PASSWORD': return (
                <div className="change-password-username">
                    <p>{`Insira o código que enviamos para o ${username} e digite uma nova senha!`}</p>
                    <form className="form-content" autoComplete="off" onSubmit={handleChangePassword}>
                        <TextInput
                            label="Código de segurança"
                            value={form.code}
                            onChange={(e) => {
                                if (GreatMask.onlyNumber(e.target.value).length < 7)
                                    setForm({ ...form, code: GreatMask.onlyNumber(e.target.value) })
                            }}
                            className='change-password-field'
                            required
                            error
                        />
                        <TextInputPassword
                            label="Senha *"
                            value={form.password}
                            onChange={(e) => { setForm({ ...form, password: e.target.value }) }}
                            validValue='minNumberText'
                            minLength={6}
                            className='change-password-field'
                            error
                            required
                        />
                        <TextInputPassword
                            label="Confirme a senha *"
                            value={form.confirmPassword}
                            onChange={(e) => { setForm({ ...form, confirmPassword: e.target.value }) }}
                            validValue='minNumberText'
                            minLength={6}
                            className='change-password-field'
                            error
                            required
                        />
                        {form.password !== form.confirmPassword && (
                            <span className='change-password-field warning-change-password'>
                                As senhas devem ser iguais!
                            </span>
                        )}
                        <button
                            className={clsx("button", "change-password-field", !buttonDisabled ? "button-blue" : "button-gray")}
                            disabled={buttonDisabled}
                            type='submit'
                        >
                            Alterar senha
                        </button>
                    </form>
                </div>
            );
            default: return (
                <Loader loading={loading} />
            )
        }
    }

    return (
        <>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {!loading ? getComponent(step) : (<Loader loading={loading} />)}
        </>
    )
}