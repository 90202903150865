import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextInput from '../../App/components/TextInput';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../../App/utils/Toast';

export default function RegisterWidget({ onRegister }) {

    const [barcode, setBarcode] = useState('');

    const [searchType, setSearchType] = useState('ticket');

    const notifyError = () => toast.error('Informe o Ticket ou Placa!', {

        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });

    function handleRegister() {

        if (typeof onRegister === 'function' && barcode !== '') {

            onRegister(searchType, barcode);

            setBarcode('');
        }
        else
            Toast.error('Informe o Ticket ou Placa!');
    }

    function handleKeyPress(e) {

        if (typeof onRegister === 'function' && e.key === 'Enter' && barcode !== '') {

            onRegister(searchType, barcode);

            setBarcode('');
        }
    }

    // verifica se o que vai ser pesquisado é ticket ou placa
    function checkTicketPlaca (value) {

        // value  = value.toString();

        if (searchType === 'ticket') {


            if (value.length === 9)
                return;

            /*if (value.length === 2) {

                setBarcode('')

                return
            }*/

            value = value.replace(/\D/g, "").replace(/(\d{1})(\d)/, "$1-$2");

            setBarcode(value)
        }

        if (searchType === 'plaque' && value.length === 8)
            return;

        if (value.length === 1) {

            if (isNumber(value)) {

                // setBarcode(value = value + '-')
                setBarcode(value.replace(/\D/g, "").replace(/(\d{1})(\d)/, "$1-$2"))

                setSearchType('ticket')
            }
            else {

                setSearchType('plaque')

                setBarcode(value)
            }
        }
        else
            setBarcode(value)
    }

    function isNumber(char) {

        if (typeof char !== 'string') {
            return false;
        }

        if (char.trim() === '') {
            return false;
        }

        return !isNaN(char);
    }

    return (
        <Card id="widget-container">
            <CardContent className="widget-card-content">

                <Typography variant="h5">Vincular Estacionamento</Typography>

                <div className="content-value">
                    <TextInput
                        label="Ticket / Placa"
                        value={barcode}
                        className="textField"
                        onChange={(e) => checkTicketPlaca(e.target.value.toUpperCase())}
                        onKeyPress={handleKeyPress}
                        autoFocus
                    />
                </div>

            </CardContent>

            <CardActions className="footer-card-content">
                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleRegister}
                >
                    Registrar
                </Button>
            </CardActions>
        </Card>
    );
}
