import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let id = '';

const Toast = {
	
	loading: (msg) => {

		id = toast.loading(msg, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored"
                         });

		return id;
	},

	update: (msg, type) => {

		setTimeout(function() {
    
            toast.update(id, {render: msg, theme: "colored", type: type, isLoading: false, autoClose: 2000, })

        }, 1000);

	},

    update2: (msg, type) => {

        setTimeout(function() {
    
            toast.update(id, {render: msg, theme: "colored", type: type, isLoading: false, autoClose: 2000, })

        }, 1000);

    },

	success: (msg) => {

		toast.success(msg, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "colored"
                     });

	},
	error: (msg) => {

        toast.error(msg, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                  });
	},

	showToast: (resp, msg) => {

		// console.log(resp)

        (typeof resp === 'boolean' && resp) ? Toast.update(msg, "success") : Toast.update("Erro: " + resp, "error");
    },

    dismiss: () => {

        toast.dismiss();
    }

}

export default Toast;