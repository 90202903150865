import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GreatMask from '../../App/utils/GreatMask';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function HistoryTable({ resultSet }) {

    const classes = useStyles();

    const licensingValueTotal = resultSet.reduce((accumulator, parking) => {

      return accumulator + Number(parking.licensing_value_total)

    }, 0);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">

                <TableHead>
                    <TableRow>
                        <TableCell>Entrada</TableCell>
                        <TableCell>Finalizado</TableCell>
                        <TableCell>Estabelecimento</TableCell>
                        <TableCell>Usuário</TableCell>
                        <TableCell>Placa</TableCell>
                        <TableCell>Ticket</TableCell>
                        <TableCell align="center">Permanência</TableCell>
                        <TableCell>Valor</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {resultSet.map((history, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{history.arrival}</TableCell>
                            <TableCell>{history.finished_at === '' ? 'EM ANDAMENTO' : history.finished_at}</TableCell>
                            <TableCell>{history.establishment.name}</TableCell>

                            <TableCell>{history.id_licensing_user_external_id ? 'Estacionamento' : 'Loja'}</TableCell>

                            <TableCell>{history.plaque}</TableCell>
                            <TableCell>{history.external_id}</TableCell>
                            <TableCell align="center">{GreatMask.minutesToHoursMinutes(history.minutes_elapsed)}</TableCell>
                            <TableCell>R$ {GreatMask.coinBR(history.licensing_value_total)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

                <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Total: R$ {GreatMask.coinBR(licensingValueTotal)}</TableCell>
                </TableRow>

            </Table>
        </TableContainer>
    );
}
