export const CLOSE_BACKDROP = 'CLOSE_BACKDROP';
export const OPEN_BACKDROP = 'OPEN_BACKDROP';

const INITIAL_STATE = {
    backdropIsOpen: false
}

export function appRecucer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case OPEN_BACKDROP:
            return {
                ...state,
                backdropIsOpen: true
            }
        case CLOSE_BACKDROP:
            return {
                ...state,
                backdropIsOpen: false
            }
        default:
            return state
    }
}