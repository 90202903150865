export const GET_PARKING = 'GET_PARKING';
export const RESET_PARKING = 'RESET_PARKING';

const INITIAL_STATE = {
    parking: {}
}

export function registerReducer(state = INITIAL_STATE, action) {

    // console.log('register reducer: '+action.type)

    switch (action.type) {
        
        case GET_PARKING:

            // console.log(action.payload)
            
            return {
                ...state,
                parking: action.payload
            }

        case RESET_PARKING:

            return {
                ...INITIAL_STATE,
            }
        default:
            return state
    }
}