import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GreatMask from '../../App/utils/GreatMask';
import useStyles from "../../App/hooks/useStyles";

export default function SummaryHistoryTable({ resultSet }) {

    const classes = useStyles();
    
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Entrada</TableCell>
                        <TableCell>Finalizado</TableCell>
                        <TableCell>Placa</TableCell>
                        <TableCell>Ticket</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {resultSet.map((history, index) => {
                        return index < 10 && (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">{history.arrival}</TableCell>
                                <TableCell>{history.finished_at === '' ? 'EM ANDAMENTO' : history.finished_at}</TableCell>
                                <TableCell>{history.plaque}</TableCell>
                                <TableCell>{history.external_id}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}