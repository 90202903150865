import axios from 'axios';
import StorageService from './StorageService';
import {history} from './HistoryService';
import {refreshInterceptor} from "./RefreshService";

export const baseURL = 'https://api.prod.estacionefacil.consforsistemas.com.br';
// export const baseURL = 'http://192.168.0.198:4001';

export const ApiService = {

    post: async (uri, data) => {

        const config = await getToken();

        return axios.post(baseURL + uri, data, config)
            .then(response => {
                response['data']['headers'] = response.headers;
                return response['data'];
            }).catch(error => getError(error));
    },

    get: async (uri) => {

        const config = await getToken();

        // console.log(uri)

        return axios.get(baseURL + uri, config)
            .then(response => response['data']).catch(error => getError(error));
    },
    patch: async (uri, data) => {
        const config = await getToken();
        return axios.patch(baseURL + uri, data, config)
            .then(response => response['data']).catch(error => getError(error));
    },
    delete: async (uri, data) => {
        let config = await getToken();
        return axios.delete(baseURL + uri, {...config, data})
            .then(response => response['data']).catch(error => getError(error));
    },
}

async function getToken() {

    await refreshInterceptor();

    const token = await StorageService.get('token');

    return {

        headers: {

            'Content-Type': 'application/json',
            'Authorization': token
        },

        withCredentials: true,
    }
}

function getError(error) {

    if (error.response) {

        switch (error.response.status) {

            case 400:
                return  error.response.data;

            case 401:

                window.localStorage.clear();
                history.push('/');
                alert('Sessão expirada, por favor realize login novamente');
                break;

            case 403:
                return  error.response.data;

            default:
                return  error.response.data;
        }
    }

    if (error.request) {
        console.warn(`The request was made but no response was received`, error.request);
        return {
            resultSet: [],
            message: 'Erro interno no servidor',
            affectedRows: 0,
            success: false
        }
    }

    console.error('Something happened in setting up the request that triggered an Error', error.message);
    return {
        resultSet: [],
        message: 'Erro interno no servidor',
        affectedRows: 0,
        success: false
    }


}
