import { ApiService } from '../App/services/ApiService';
import StorageService from '../App/services/StorageService';
import { SET_USER, RESET_USER } from '../MyAccount/userReducers';

export function login(username, password, callback) {
    return dispatch => {

        ApiService.post('/authentication/log-in', { username, password }).then((response) => {

            window.localStorage.clear();

            if (response === 0) {
                return callback('Verifique a sua internet e tente novamente.');
            }

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }

            if (response?.success) {

                let accessOk = response.resultSet[0].userAccessGroup?.map((access) => {

                    if (access?.id_access_group === 4)
                        return true
                    else
                        return false
                })


                if (accessOk.length <= 0 || !accessOk.some((x) =>  x === true)) {
                    return callback('Você não tem permissão para acessar.')
                }

                StorageService.add('token', response.headers['authorization-token']);
                StorageService.add('refresh', response.headers['refresh-token']);

                dispatch({
                    type: SET_USER,
                    payload: {
                        user: response.resultSet[0]
                    }
                });

                if (typeof callback === 'function')
                   return callback(true);
            }

            if (typeof callback === 'function')
                callback('Usuário ou senha incorreto. Verifique os dados informados e tente novamente.');
        })
    }
}

export function logout(callback = () => {
}) {
    return dispatch => {
        ApiService.post('/authentication/log-out', {}).then(() => {

            window.localStorage.clear();

            dispatch({
                type: RESET_USER
            });

            if (typeof callback === 'function')
                callback(true);
        })

    }
}
