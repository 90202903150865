import { combineReducers } from 'redux';

import { userReducer } from '../../MyAccount/userReducers';
import { historyReducer } from '../../History/historyReducers';
import { registerReducer } from '../../Dashboard/registerReducers';
import {dialogReducer} from "../components/dialog/dialogReducer";
import {appRecucer} from "./appReducer";

const Reducers = combineReducers({
    user: userReducer,
    dialog: dialogReducer,
    history: historyReducer,
    register: registerReducer,
    app: appRecucer,
});

export default Reducers;