import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import LoginImage from './loginImage.jpg';
import FormLogin from './components/FormLogin';
import './styles.css';
import { ToastContainer } from 'react-toastify';

export default function Login() {

    useEffect(() => {
        window.localStorage.clear();
    }, [])

    return (
        <Grid container>

            <ToastContainer />

            <Hidden smDown >
                <Grid item xs={false} sm={false} md={6} lg={8} xl={8} className="img-container" >
                    <img src={LoginImage} alt="Imagem de estacionamento" />
                </Grid>
            </Hidden>

            <Grid item sm={12} md={6} lg={4} xl={4} className="form-container">
                <FormLogin />
            </Grid>
        </Grid>
    );
}
