import { ApiService } from '../App/services/ApiService';
import { RESET_USER } from './userReducers';

export function recoverPassword(user, callback) {
    return () => {
        ApiService.post('/authentication/forgot-password', {
            username: user
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Tente novamente');
            }
            if (response.data.success && typeof callback === 'function') {
                callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })
    }
}

export function changePassword(data, callback) {
    return () => {
        ApiService.patch('/authentication/change-password', {
            password: data.password,
            check_code: data.code
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Tente novamente');
            }
            if (response.success && typeof callback === 'function') {
                callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })
    }
}

export function resetUser() {
    return dispatch => {
        dispatch({
            type: RESET_USER
        });
    }
}