export const CHANGE_OPEN_RESPONSIVE_CONFIRM = 'CHANGE_OPEN';

const INITIAL_STATE = {
    confirmResponsive: {
        open: false
    },
}

export function dialogReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CHANGE_OPEN_RESPONSIVE_CONFIRM:
            return {
                ...state,
                confirmResponsive: {
                    ...state.confirmResponsive,
                    open: action.payload.open
                }
            }
        default:
            return state
    }
}