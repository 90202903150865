import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Motorcycle from "@material-ui/icons/Motorcycle";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DirectionsBus from "@material-ui/icons/DirectionsBus";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useColors} from '../../App/hooks/useColors';
import { data as VehicleType } from '../../App/utils/typesVehicles';
import { types as EstablishmentTypes } from '../../App/utils/typesEstablishment';
import './styles.css';

export default function ConfirmBarcodeDialog({ open, data, onConfirm, cancel }) {

    const colors = useColors();

    const [freeParking, setFreeParking] = useState(false);

    function handleConfirm() {

        if (typeof onConfirm === 'function')
            onConfirm(freeParking, data);
    }

    function handleClose() {

        if (typeof cancel === 'function') cancel(false);
    }

    function getIcon(icon) {

        switch (icon) {

            case 1: return <DirectionsCar style={{ color: colors.greenCS, fontSize: 44 }} />
            case 2: return <Motorcycle style={{ color: colors.greenCS, fontSize: 44 }} />
            case 3: return <LocalShipping style={{ color: colors.greenCS, fontSize: 44 }} />
            case 4: return <DirectionsBus style={{ color: colors.greenCS, fontSize: 44 }} />

            default: return <DirectionsCar style={{ color: colors.greenCS, fontSize: 44 }} />
        }
    }

    const vehicleType   = VehicleType[data?.id_vehicle_type - 1].name;
    const establishType = EstablishmentTypes[data?.id_parking_type - 1].name;
    const day           = data?.arrival.substring(0, 10);
    const hour          = data?.arrival.substring(12, 17);

    return (

        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm">

            <DialogContent>
                <Typography variant="h5" className="dialog-title">Confirmação</Typography>

                <div className="content-confirm-barcode">
                    <Typography variant="subtitle1" className="dialog-title">
                        Confirma os dados do veículo para terminar o registro.
                    </Typography>
                    <div className='icon'>
                        {getIcon(data?.id_vehicle_type)}
                    </div>

                    <div className="content first">
                        <div className="content">
                            <Typography variant="subtitle1" className="title">Data:</Typography>
                            <Typography variant="subtitle1">{day}</Typography>
                        </div>
                        <div className="content">
                            <Typography variant="subtitle1" className="title">Hora:</Typography>
                            <Typography variant="subtitle1">{hour}</Typography>
                        </div>
                    </div>

                    <div className="content-data">
                        <div className="data">
                            <Typography variant="h6" className="section-name">Veículo</Typography>
                            <div className="content">
                                <Typography variant="subtitle1" className="title">Tipo do veículo:</Typography>
                                <Typography variant="subtitle1">{vehicleType}</Typography>
                            </div>
                            <div className="content">
                                <Typography variant="subtitle1" className="title">Placa:</Typography>
                                <Typography variant="subtitle1">{data?.plaque}</Typography>
                            </div>
                        </div>
                        <div className="data">
                            <Typography variant="h6" className="section-name">Estacionamento</Typography>
                            <div className="content">
                                <Typography variant="subtitle1" className="title">Estabelecimento:</Typography>
                                <Typography variant="subtitle1">{data?.establishment.name}</Typography>
                            </div>
                            <div className="content">
                                <Typography variant="subtitle1" className="title">Tipo:</Typography>
                                <Typography variant="subtitle1">{establishType}</Typography>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="content-free">
                    <div className="free">
                        <Typography variant="h6" className="title">Gratuito</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={freeParking}
                                    onChange={(e) => { setFreeParking(!freeParking) }}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label={freeParking ? "SIM" : "NÃO"}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
