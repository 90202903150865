import { ApiService } from '../App/services/ApiService';
import { GET_PARKING } from './registerReducers';
import { SET_HISTORY } from '../History/historyReducers';
import { SauronApi } from "../App/services/SauronApi";
import { format } from 'date-fns';

export function sendBarcodeRegister(searchType, barcode, insert_licensing_list, id_establishment, callback) {

    const initialDate   = format(new Date(), 'yyyy-MM-dd');
    const finalDate     = format(new Date(), 'yyyy-MM-dd');

    let external_id = '';
    let plaque      = '';

    if (searchType === 'ticket')
        external_id = barcode;

    if (searchType === 'plaque')
        plaque = barcode

    return dispatch => {

        ApiService.get(`/parking/licensing?external_id=${external_id}&plaque=${plaque}&initDate=${initialDate}&finalDate=${finalDate}&id_establishment=${id_establishment}&insert_licensing_list=${insert_licensing_list}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro no envio da barra de código');
            }

            // console.log(response.resultSet[0])

            if (response?.success && typeof callback === 'function') {

                if (insert_licensing_list) {

                    dispatch({
                        type: SET_HISTORY,
                        payload: [response.resultSet[0]]
                    });
                }
                else {

                    dispatch({
                        type: GET_PARKING,
                        payload: response.resultSet[0]
                    });
                }

                if (response?.resultSet.length <= 0) {
                    return callback(response?.message);
                }

                return callback(response.resultSet[0]);
            }

            if (typeof callback === 'function')
                callback('Ticket ou Placa inválido, verifique e tente novamente');
        })
    }
}


export function sendConfirmRegister2(data, callback) {

    return dispatch => {

        ApiService.patch('/parking/licensing', {

            id_parking: Number(data.id_parking),
            parking_free: false,
            phone_number: data.phone_number,

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro no envio da barra de código');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({
                    type: GET_PARKING,
                    payload: response.resultSet[0]
                });

                if (response?.resultSet.length <= 0) {
                    return callback(response?.message);
                }

                return callback(true);
            }

            if (typeof callback === 'function')
                callback('Código de barra inválido, verifique e tente novamente');
        })
    }
}


// envia direto para o estacionamento via socket
export function sendConfirmRegister(data, callback) {

    return dispatch => {

        SauronApi.post('/exec', {

            command: 'sync_licensing_ticket',
            client_id: data.params.client_id,

            params: {
                ticket_id:      data.params.ticket_id,
                licensing:      data.params.licensing,
                document:       data.params.document,
                phone_number:   data.params.phone_number,
                parking_free:   data.params.free.toString()
            },

        }).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function')
                return callback('Erro ao enviar os dados para confirmação');

            if (response.action === 'error')
                return callback(response.message)

            dispatch({
                type: SET_HISTORY,
                payload: [data.filteredParking]
            });

            return callback(true);
        })
    }
}
